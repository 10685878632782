import React from 'react'

const DashboardPage = () => {

    return (
        <div>
            <h2>Dashboard Page</h2>
        </div>
    )
}

export default DashboardPage;
