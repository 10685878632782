import React, {useRef} from 'react';
import {Parallax, ParallaxLayer} from "react-spring/renderprops-addons";


const LandingPage = () => {
    let parallax = useRef();
    return (
        <Parallax ref={ref => {
            parallax = ref
        }} pages={3}>
            <ParallaxLayer offset={0} speed={1.5} style={{
                display: 'flex',
                flex: 1,
            }}>

                <div style={{height: 80, color: 'white', flex: 1, flexDirection: 'row', display: 'flex'}}>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                        <img src='./logo.png' style={{marginLeft: 42, marginTop: 22}}/>
                    </div>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <a href='/register'
                           className='glow'
                           style={{
                               borderRadius: 15,
                               marginTop: 37,
                               marginRight: 37,
                               backgroundColor: '#fff',
                               color: '#001f3b',
                               textDecoration: 'none',
                               fontSize: 32,
                               textTransform: 'uppercase',
                               paddingTop: 8,
                               paddingBottom: 8,
                               paddingLeft: 21,
                               paddingRight: 21
                           }}>Zarejestruj</a>
                    </div>
                </div>

            </ParallaxLayer>

            <ParallaxLayer offset={0} speed={-0.3} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <div style={{padding: 50, color: 'white', flex: 1}}>
                    <h1>Analiza techniczna</h1>
                    <p style={{padding: 50}}>Analiza techniczna notowań na parkiecie GPW. Świece, formacje i statystyki,
                        które pomoga Ci grac na gieldzie.</p>
                </div>
                <div style={{flex: 0.5}}/>

            </ParallaxLayer>


            <ParallaxLayer offset={0} speed={0.9} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <div style={{padding: 50, color: 'white', flex: 1}}/>
                <div style={{flex: 0.5}}>
                    <img src='./technical.png' style={{width: 250}} className='glow'/>
                </div>
            </ParallaxLayer>

            <ParallaxLayer offset={1} speed={-0.3} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <div style={{padding: 50, color: 'white', flex: 1}}>
                    <h1>Notyfikacje</h1>
                    <p style={{padding: 50}}>Otrzymuj notyfikacje i bądź na bieżąco!</p>
                </div>
                <div style={{flex: 0.5}}>
                    <img
                        style={{
                            width: 250,
                        }}
                        src='./bell-ring.svg'/>
                </div>

            </ParallaxLayer>


            <ParallaxLayer offset={0} speed={0.9} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <div style={{padding: 50, color: 'white', flex: 1}}/>
                <div style={{flex: 0.5}}>
                    <img src='./technical.png' style={{width: 250}} className='glow'/>
                </div>
            </ParallaxLayer>


            <ParallaxLayer offset={2} speed={-0.3} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <div style={{padding: 50, color: 'white', flex: 1}}>
                    <h1>Aplikacja mobilna</h1>
                    <p style={{padding: 50}}>Zainstaluj aplikację mobilną i korzystaj z powiadomień giełdowych tu i
                        teraz. Otrzymuj notyfikacje i bądź na bieżąco!</p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'center'
                    }}>

                        <img src='./google-play-badge.png' style={{height: 99}}/>
                        <img src='./apple-badge.svg' style={{height: 69, padding: 15}}/>
                    </div>
                </div>
                <div style={{padding: 50, flex: 1}}/>
            </ParallaxLayer>

            <ParallaxLayer offset={2} speed={0.9} style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <div style={{padding: 50, color: 'white', flex: 1}}/>
                <div style={{padding: 50, flex: 1}}>
                    <img
                        style={{
                            width: 450
                        }}
                        src='./phone.png'/>
                </div>
            </ParallaxLayer>
        </Parallax>
    )
}

export default LandingPage
